import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import * as TYPES from '../../constants/actionTypes';
import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getLocation = (location) => (dispatch, getState, { api }) => {
  const url = APIS.LOCATION.replace('{id}', location);

  return api.get(url).then((result) => {
    dispatch({
      type: TYPES.REFRESH_LOCATION,
      data: {
        location: result
      }
    });
    return result;
  });
};

export const validateGatewayDetails = (locationId) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.VALIDATE_GATEWAY_DETAILS.replace('{location_id}', locationId);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateServiceableLocation = (location, payload) => (
  dispatch,
  getState,
  { api }
) => {
  const url = Helpers.getUrl(APIS.SERVICEABLE_ZIP_CODE, {
    location
  });

  return api
    .put(url, payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLocationStats = (location, from, to) => (
  dispatch,
  getState,
  { api }
) => {
  let url = APIS.LOCATION_STATS.replace('{id}', location);
  url = Helpers.getUrl(url, {
    from,
    to
  });
  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateLocation = (data, location) => (dispatch, getState, { api }) => {
  const url = APIS.UPDATE_LOCATION.replace('{location_id}', get(location, 'id'));

  const allLocations = get(getState(), 'location.all_location');
  const index = findIndex(allLocations, ['id', get(location, 'id')]);

  return api.put(url, data).then((result) => {
    allLocations[index] = result;
    dispatch({
      type: TYPES.UPDATE_CURRENT_LOCATION,
      data: {
        current: result,
        allLocations
      }
    });
  });
};

export const getCurrency = (user) => (dispatch, getState, { api }) => {
  return api.get(APIS.CURRENCY).then((result) => {
    return result;
  });
};

export const getCountry = () => (dispatch, getState, { api }) => {
  return api.get(APIS.COUNTRY).then((result) => {
    return result;
  });
};

export const getStates = (country) => (dispatch, getState, { api }) => {
  const url = APIS.STATE.replace('{country_id}', country);

  return api.get(url).then((result) => {
    return result;
  });
};

export const getTimeZone = () => (dispatch, getState, { api }) => {
  return api.get(APIS.TIME_ZONE).then((result) => {
    return result;
  });
};

export const changeLocation = (locationId, allLocations) => async (
  dispatch,
  getState,
  { api }
) => {
  const newLocation = find(allLocations, ['id', locationId]);

  dispatch({
    type: TYPES.UPDATE_CURRENT_LOCATION,
    data: {
      current: newLocation,
      allLocations
    }
  });
  return true;
};
